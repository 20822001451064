lm.DOMReady(() => {
  const buttonsModal = document.querySelectorAll('.arm-homepage-open-modal');
  if (buttonsModal) {
    buttonsModal.forEach(b => b.addEventListener('click', event => {
      const io = b.getAttribute('data-io');
      document.dispatchEvent(new CustomEvent("arm-popin-dsa-open", {
        detail: io
      }));
      event.preventDefault();
    }));
  }
});